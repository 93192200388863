import { ToastContainer } from 'react-toastify';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Page1 from './components/page1'
import Page2 from './components/page2'
import Page3 from './components/page3'
import Page4 from './components/page4'
import Page5 from './components/page5'
import Page7 from './components/page7'


function App() {


  return (
      <>
      <ToastContainer />
            <div className="App">
              <Navbar />
              <Page1 />
              <Page2 />
              <Page3 />
              <Page4 />
              <Page5 />
              <Page7 />
              <Footer />
      </div>
      </>
  );
}

export default App;
