import React, { useState, useEffect } from 'react';
import '../Styles/Navbar.scss';
// import logo from '../assets/NEYO HEALTH-B5.png';
import logo_navbar from '../assets/NEYO_crop-nobg.png';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop;

      if (scrollTop > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () =>
      window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <ToastContainer />
      <nav
        className={`navbar${isScrolled ? ' scrolled' : ''}`}
      >
        <Link
          to="/"
          className="navbar__logo"
        >
          <img
            src={logo_navbar}
            alt="Logo"
            width="80"
            height="80"
          />
        </Link>

        <div
          className={`navbar__links ${
            isOpen ? 'open' : ''
          }`}
        >
          <a
            href="#contact"
            onClick={closeNav}
          >
            Contact Us
          </a>
        </div>

        <div
          className="navbar__burger"
          onClick={toggleNav}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
