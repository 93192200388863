import React from 'react';
import '../Styles/page3.scss'; // Make sure to create a CSS file with the name ResponsiveComponent.css

const Page3 = () => {
  return (
    <div className="page3-cont">
      <h2>WHY TAKE THE NEYO ASSESSMENT?</h2>
      <div className="container">
        <div className="box">
          <p>
            Receive detailed feedback on many
            inter-connected aspects of your health.
          </p>
          <p>
            Discover the root cause of the health issues you
            may experience.
          </p>
        </div>
        <div className="box">
          <p>
            Learn how to prevent, manage or reverse health
            issues.
          </p>
          <p>
            Find out how to heal yourself via your own
            unique path.
          </p>
        </div>
        <div className="box">
          <p>
            Optimise your wellbeing, performance, body shape
            and extend your health span.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page3;
