import React from 'react';
import '../Styles/page5.scss';
import runner from '../assets/5 (1).jpg';

const Page5 = () => {
  return (
    <div className="health-assessment">
      <div className="image-container">
        {/* Replace with actual image source */}
        <img
          src={runner}
          alt="Health Assessment"
        />
      </div>
      <div className="content-charge">
        <h2>SUPERCHARGE YOURSELF</h2>
        <p>
          There is so much health information available that
          it can become confusing with what to apply. We may
          become frustrated not knowing how to balance our
          own health.
        </p>
        <p>
          NEYO Health has created a way for you to get
          answers and receive the care you deserve.
        </p>
        <p>
          Health is the foundation of life, it is our most
          important asset. Take the NEYO assessment and
          learn how to supercharge yourself with health.
        </p>
      </div>
    </div>
  );
};

export default Page5;
