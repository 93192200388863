import React from 'react';
import '../Styles/page7.scss';
import coming from '../assets/NEYO Health.jpg';

const Page7 = () => {
  return (
    <div>
      <a
        href="https://www.instagram.com/neyohealth/"
        aria-label="Instagram Link"
      >
        <img
          src={coming}
          alt="coming soon"
          className="coming-img"
        />
      </a>
    </div>
  );
};

export default Page7;
