import React from 'react';
import '../Styles/page4.scss';
import adults from '../assets/2 (1).jpg';
import fruits from '../assets/3 (1).jpg';
import athlete from '../assets/4 (1).jpg';

const Page4 = () => {
  return (
    <div className="neyo-container">
      <div className="neyo-header">
        <h2>WHO IS NEYO FOR ?</h2>
      </div>
      <div className="neyo-audiences">
        <div className="neyo-audience">
          <p>
            All adults that would like genuine health
            guidance.
          </p>
          <img
            src={adults}
            alt="Adults group"
          />
        </div>
        <div className="neyo-audience">
          <p>Those seeking natural wellbeing solutions.</p>
          <img
            src={fruits}
            alt="Natural wellbeing products"
          />
        </div>

        <div className="neyo-audience">
          <p>
            Beginners to professional athletes that strive
            for better performance and longevity.
          </p>
          <img
            src={athlete}
            alt="Athlete stretching"
          />
        </div>
      </div>
    </div>
  );
};

export default Page4;
