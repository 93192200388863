import React from 'react';
import { useRef } from 'react';
import '../Styles/Footer.scss';
import logo from '../assets/NEYO_crop-nobg.png';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import emailjs from '@emailjs/browser';

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_KEY,
        process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
        form.current,
        process.env.REACT_APP_EMAILJS_API_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset(form);
  };

  return (
    <footer
      id="contact"
      className="footer-container"
    >
      <div className="footer">
        <div className="footer__contact">
          <h3 className="contact-title">CONTACT</h3>
          <p>Dubai, UAE </p>
          <p style={{ color: '#FFDBBA' }}>
            Email: info@neyohealth.com
          </p>
        </div>
        <div className="form-container">
          <h4>HAVE A QUESTION?</h4>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="footer__form"
          >
            <label htmlFor="name"></label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              required
            />
            <label htmlFor="email"></label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              required
            />
            <label htmlFor="message"></label>
            <textarea
              className="message-box"
              id="message"
              name="message"
              placeholder="Message"
              required
            ></textarea>
            <button
              type="submit"
              value="Send"
            >
              Send
            </button>
          </form>
        </div>
        <div className="footer__social">
          {/* <h3>FOLLOW US</h3> */}
          {/* <div className="social-icons">
            <a
              href="https://www.instagram.com/neyohealth/"
              aria-label="Instagram Link"
            >
              <InstagramIcon />
            </a>
            <a href="https://www.facebook.com">
              <FacebookIcon />
            </a>
            <a href="https://www.youtube.com">
              <YouTubeIcon />
            </a>
          </div> */}
          <img
            src={logo}
            alt="Logo"
            width="65"
            height="60"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
