import '../Styles/page1.scss';
import logo from '../assets/NEYO HEALTH-B5.png';

const Page1 = () => {
  return (
    <div className="logo-container">
      <img
        src={logo}
        alt="logo"
      />
    </div>
  );
};

export default Page1;
