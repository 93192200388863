// Filename: HealthAssessment.js
import React from 'react';
import '../Styles/page2.scss'; // Make sure to create this SCSS file
import pageImage from '../assets/1 (1).jpg';

const Page2 = () => {
  return (
    <div className="health-assessment">
      <div className="content-what">
        <h2>WHAT IS NEYO?</h2>
        <p>
          NEYO is a new age health assessment that will help
          you <b>discover what your body is telling you™</b>
          .
        </p>
        <p>
          A 60 minute, non invasive analysis that can be
          done online with one of our health practitioners.
        </p>
      </div>
      <div className="image-container">
        {/* Replace with actual image source */}
        <img
          src={pageImage}
          alt="Health Assessment"
        />
      </div>
    </div>
  );
};

export default Page2;
